import React from 'react';
import { Button, Table, Pagination } from 'react-bootstrap';

interface PaginationProps {
  page: number;
  pages: number;
  gotoPage: (count: number, page: number) => void;
}

const MyPagination: React.FC<PaginationProps> = ({
  page = 0,
  pages = 0,
  gotoPage = () => {},
}) => {
  const renderPagination = (page: number, pages: number) => {
    const items = [];
    let start = 0;
    let end = pages;
    const interval = 3;

    if (pages > 3 && page > interval) {
      start = page - interval;
      items.push(<Pagination.First />);
      items.push(<Pagination.Prev />);
      items.push(
        <Pagination.Item onClick={() => {
          gotoPage(0, page);
        }}
        >
          1
        </Pagination.Item>
      );
      items.push(<Pagination.Ellipsis />);
    }

    if (pages > 3 && page + interval < pages) {
      end = page + interval;
    }

    for (let count = start; count < end; count++) {
      items.push(
        <Pagination.Item key={`page-${count}`} active={count === page} onClick={() => {
          gotoPage(count, page);
        }}
        >
          {count+1}
        </Pagination.Item>
      );
    }

    if (pages > 3 && page+1 < pages ) {
      start = page - interval;
      end = page + interval;

      items.push(<Pagination.Ellipsis />);
      items.push(
        <Pagination.Item onClick={() => {
          gotoPage(pages-1, page);
        }}
        >
          {pages}
        </Pagination.Item>
      );
      items.push(<Pagination.Next />);
      items.push(<Pagination.Last />);
    }

    return items;
  };

  return (
    <Pagination>{renderPagination(page, pages)}</Pagination>
  );
};

export default MyPagination;
