import React from 'react';
import { Table } from 'react-bootstrap';
import { currency } from '../utils/Helper';

interface TransactionsProps {
  data?: any[];
}

const Transactions: React.FC<TransactionsProps> = ({
  data = [],
}) => {
  return (
    <div className="mainWrapper">
      {data.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Order Key</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: any) => {
              return (
                <tr key={`transaction-${item.key}`}>
                  <td>{item.createdAt}</td>
                  <td>{item.type}</td>
                  <td>{item.orderKey}</td>
                  <td>{currency(item.amount)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div>
          No transactions found
        </div>
      )}
    </div>
  );
};

export default Transactions;
