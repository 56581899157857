import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface MembersProps {
  data?: any[];
}

const Members: React.FC<MembersProps> = ({
  data = [],
}) => {
  const navigate = useNavigate();

  return (
    <div className="mainWrapper">
      {data.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th>Status</th>
              <th>Balance</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: any) => {
              return (
                <tr key={`member-${item.id}`}>
                  <td>{`${item.lastname}, ${item.firstname} ${item.middlename}`}</td>
                  <td>{item.email}</td>
                  <td>{item.type}</td>
                  <td className={`status-${item.status}`}>{item.status}</td>
                  <td>{item.balance}</td>
                  <td>
                    <Button
                      onClick={() => {
                        navigate(`/member/details/${item.id}`);
                      }}
                    >
                      Details
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div>
          No members found
        </div>
      )}
    </div>
  );
};

export default Members;
