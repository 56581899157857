import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, db } from 'services/Firebase';
import { fetcher } from 'utils/Helper';

export const getOrders = createAsyncThunk('orders/getOrders', async (params: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await fetcher(`${process.env.REACT_APP_API}/order/listByAdmin`, token, params);

  console.log('res', data);
  if (data.code === 100) {
    return data;
  } else {
    return null;
  }
});

export const getOrder = createAsyncThunk('orders/getOrder', async (orderId: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await fetcher(`${process.env.REACT_APP_API}/order/details`, token, { orderId });

  console.log('res', data);
  if (data.code === 100) {
    return data.order;
  } else {
    return null;
  }
});

export const addOrder = createAsyncThunk('orders/addOrder', async (params: any, { dispatch }) => {
  console.log('addOrder', params);
  return true;
});

export const updateOrder = createAsyncThunk('orders/updateOrder', async (params: any, { dispatch }) => {
  const { key, status } = params;

  db.ref(`/orders/${key}/status`).set(status);

  return true;
});

export const approveOrder = createAsyncThunk('orders/approveOrder', async (order: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await fetcher(`${process.env.REACT_APP_API}/order/process`, token, { orderId: order.id });

  return data;
});


const initialState:any = null;

const OrdersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: { ordersReset: () => initialState, },
  extraReducers: (builder) => {
    builder.addCase(getOrders.fulfilled, (state: any, action: any) => {
      return action.payload;
    });
  }
});

export const { ordersReset } = OrdersSlice.actions;

export default OrdersSlice.reducer;
