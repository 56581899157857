import React, { useContext, useEffect, useState, createRef } from 'react';
import { Form, Card, Col, InputGroup, Row, Button, Alert, Table, Modal, Spinner, Image } from 'react-bootstrap';
import diel from 'assets/diel.jpg';

const Splash: React.FC = () => {

  return (
    <div className="text-center">
      <Image src={diel} width="200" />
      <div><Spinner animation="border" /></div>
    </div>
  );
};

export default Splash;
