import { combineReducers } from 'redux';
import orders from './OrdersSlice';
import withdrawals from './WithdrawalsSlice';
import members from './MembersSlice';
import user from './UserSlice';

const rootReducer: any = combineReducers({
  orders,
  members,
  withdrawals,
  user
});

export default rootReducer;

