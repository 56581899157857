import axios from 'axios';

export const currency = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
  }).format(amount);
};

export const fetcher = async (
  url: string,
  token: string | undefined,
  params?: any,
  method?: string,
) => {
  const reqInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  try {
    const result =
      !method || method === 'post'
        ? await reqInstance.post(url, params || {})
        : await reqInstance.get(url);

    return result.data;
  } catch (error: any) {
    return {
      status: error.response?.status,
      message: error.message,
    };
  }
};
