import React from 'react';
import { Spinner } from 'react-bootstrap';

interface LoaderProps {
  loading: boolean;
  size?: any;
}

const Loader: React.FC<LoaderProps> = ({
  loading,
  size = 'sm',
}) => {
  if (!loading) {
    return (<></>);
  }

  return (
    <Spinner className="mr-5" size={size} animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};

export default Loader;
