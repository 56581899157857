import React, { useContext, useEffect, useState } from 'react';
import { Form, Card, Col, InputGroup, Row, Button, Alert, Table, Modal, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { addMember } from '../redux/reducers/MembersSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { DielContext } from '../core/DielContext';
import { Loader } from '../components';
import { useNavigate } from 'react-router-dom';
import { actions } from 'react-table';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  type: yup.string().required(),

  firstname: yup.string().required(),
  lastname: yup.string().required(),
  middlename: yup.string().required(),
  birthdate: yup.string().required(),
  mobile: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  province: yup.string().required(),
  zip: yup.string().required(),
}).required();

const defaultValues = {
  email: '',
  password: '',
  firstname: '',
  lastname: '',
  middlename: '',
  birthdate: '',
  mobile: '',
  address: '',
  city: '',
  province: '',
  zip: '',
  type: 'stockiest',
};

interface OnboardingModalProps {
  account?: any;
  mode?: 'view' | 'add' | 'edit';
}

const ProfileForm: React.FC<OnboardingModalProps> = ({
  account = {},
  mode = 'add',
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showToast } = useContext(DielContext);

  const { control, formState, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...account
    },
    resolver: yupResolver(schema),
  });
  const { isValid } = formState;
  const form = watch();
  const [loading, setLoading] = useState(false);
  const disabled = mode === 'view';

  const handleSave = async() => {
    setLoading(true);

    dispatch(addMember(form)).then((action: any) => {
      const data = action.payload;
      setLoading(false);

      if (data?.code === 100) {
        showToast('Successfully added');
        navigate('/members');
      } else if (data?.code === 200) {
        showToast(data?.message);
      }
    });
  };

  useEffect(() => {
    if (Object.entries(account).length > 0) {
      reset({
        ...defaultValues,
        ...account
      });
    }
  }, [account]);

  return (
    <div>
      <div className="mainWrapper">
        <h5>Authentication</h5>
        <Row>
          <Col lg="6">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    {...field}
                    className="form-control"
                    placeholder=""
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Form.Group>
              )}
            />
          </Col>

          {(mode === 'add' || mode === 'edit') && (
            <Col lg="6">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Form.Group className="form-group">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      {...field}
                      className="form-control"
                      placeholder=""
                      type="text"
                      required
                      disabled={disabled}
                    />
                  </Form.Group>
                )}
              />
            </Col>
          )}

          <Col lg="6">
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>Type</Form.Label>
                  <Form.Select {...field} className="form-control">
                    <option value="stockiest">Stockiest</option>
                    <option value="depot">Depot</option>
                    <option value="distributor">Distributor</option>
                    <option value="member">Member</option>
                  </Form.Select>
                </Form.Group>
              )}
            />
          </Col>

          {(mode === 'view') && (
            <Col lg="6">
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Form.Group className="form-group">
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                      {...field}
                      className="form-control"
                      placeholder=""
                      type="text"
                      required
                      disabled={disabled}
                    />
                  </Form.Group>
                )}
              />
            </Col>
          )}
        </Row>

        <h5>Personal Details</h5>
        <Row>
          <Col lg="6">
            <Controller
              name="firstname"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    {...field}
                    className="form-control"
                    placeholder=""
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Form.Group>
              )}
            />
          </Col>

          <Col lg="6">
            <Controller
              name="lastname"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    {...field}
                    className="form-control"
                    placeholder=""
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Form.Group>
              )}
            />
          </Col>

          <Col lg="6">
            <Controller
              name="middlename"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    {...field}
                    className="form-control"
                    placeholder=""
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Form.Group>
              )}
            />
          </Col>

          <Col lg="6">
            <Controller
              name="birthdate"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>Birth Date</Form.Label>
                  <Form.Control
                    {...field}
                    className="form-control"
                    placeholder=""
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Form.Group>
              )}
            />
          </Col>
        </Row>


        <h5>Contact</h5>
        <Row>
          <Col lg="6">
            <Controller
              name="mobile"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    {...field}
                    className="form-control"
                    placeholder=""
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Form.Group>
              )}
            />
          </Col>
        </Row>

        <h5>Address</h5>
        <Row>
          <Col lg="6">
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    {...field}
                    className="form-control"
                    placeholder=""
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Form.Group>
              )}
            />
          </Col>

          <Col lg="6">
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    {...field}
                    className="form-control"
                    placeholder=""
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Form.Group>
              )}
            />
          </Col>

          <Col lg="6">
            <Controller
              name="province"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>Province</Form.Label>
                  <Form.Control
                    {...field}
                    className="form-control"
                    placeholder=""
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Form.Group>
              )}
            />
          </Col>

          <Col lg="6">
            <Controller
              name="zip"
              control={control}
              render={({ field }) => (
                <Form.Group className="form-group">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    {...field}
                    className="form-control"
                    placeholder=""
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Form.Group>
              )}
            />
          </Col>
        </Row>

        {(mode === 'add' || mode === 'edit') && (
          <Button
            variant=""
            className="btn btn-primary btn-block"
            onClick={handleSave}
            disabled={!isValid || loading}
          >
            <Loader loading={loading} />
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProfileForm;
