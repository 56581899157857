import { configureStore, getDefaultMiddleware, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
// import reducers from './reducers';
// import storage from 'redux-persist/lib/storage'

// const STORAGE_KEY = 'rtk00'

// const persistConfig = {
//  key: STORAGE_KEY,
//  storage
// }

// const persistedReducer = persistReducer(persistConfig, reducers);

import reducers from './reducers';

export const store = configureStore({
  reducer: reducers,

  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
      ]
    }
  })
});
export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action<string>
>;

export default store;
