import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, db } from 'services/Firebase';
import { fetcher } from 'utils/Helper';

export const getWithdrawals = createAsyncThunk('withdrawals/getWithdrawals', async (params: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await fetcher(`${process.env.REACT_APP_API}/withdrawal/listByAdmin`, token, params);

  console.log('res', data);
  if (data.code === 100) {
    return data;
  } else {
    return null;
  }
});

export const getWithdrawal = createAsyncThunk('withdrawals/getWithdrawal', async (id: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await fetcher(`${process.env.REACT_APP_API}/withdrawal/details`, token, { id });

  console.log('res', data);
  if (data.code === 100) {
    return data.withdrawal;
  } else {
    return null;
  }
});

export const updateWithdrawal = createAsyncThunk('withdrawals/updateWithdrawal', async (params: any, { dispatch }) => {
  const { key, status } = params;

  db.ref(`/withdrawals/${key}/status`).set(status);

  return true;
});

export const approveWithdrawal = createAsyncThunk('withdrawals/approveWithdrawal', async (withdrawal: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await fetcher(`${process.env.REACT_APP_API}/withdrawal/approveByAdmin`, token, { id: withdrawal.id });

  return data;
});

export const rejectWithdrawal = createAsyncThunk('withdrawals/rejectWithdrawal', async (withdrawal: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await fetcher(`${process.env.REACT_APP_API}/withdrawal/rejectByAdmin`, token, { id: withdrawal.id });

  return data;
});

const initialState:any = null;

const WithdrawalsSlice = createSlice({
  name: 'withdrawals',
  initialState,
  reducers: { withdrawalsReset: () => initialState, },
  extraReducers: (builder) => {
    builder.addCase(getWithdrawals.fulfilled, (state: any, action: any) => {
      return action.payload;
    });
  }
});

export const { withdrawalsReset } = WithdrawalsSlice.actions;

export default WithdrawalsSlice.reducer;
