import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from 'services/Firebase';
import { fetcher } from 'utils/Helper';

export const getUser = createAsyncThunk('user/getUser', async (params: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await fetcher(`${process.env.REACT_APP_API}/user/details`, token);

  if (data.code === 100 && data.details.isAdmin === 1) {
    return data.details;
  } else {
    return null;
  }
});

export const updatePassword = createAsyncThunk('user/updatePassword', async (params: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await fetcher(`${process.env.REACT_APP_API}/user/details`, token, params);

  if (data.code === 100) {
    return data;
  } else {
    return null;
  }
});

export const logout = createAsyncThunk('user/logout', async (params: any, { dispatch }) => {
  auth.signOut();

  dispatch(userReset());
});

const initialState:any = null;

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: { userReset: () => initialState, },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state: any, action: any) => {
      return action.payload;
    });
  }
});

export const { userReset } = UserSlice.actions;

export default UserSlice.reducer;
